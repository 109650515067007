<template>
  <div>
    <b-row>
      <b-col lg="12">
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <b-card v-else>
          <template #header>
            <div>
              <span class="mr-1">
                {{ moment(fixture.date_time).format('LLL') }}
              </span>
              <span class="mr-1">
                <b-badge :variant="resolveStatusVariant(fixture.status)">
                  {{ fixture.status }}
                </b-badge>
              </span>
              <span class="mr-1">
                <b-badge variant="light-info">
                  {{ pointsToWin(fixture) }} points
                </b-badge>
              </span>
              <span>
                <b-badge variant="light-success">
                  {{ fixture.stage }}
                </b-badge>
              </span>
            </div>
          </template>
          <b-row class="row align-items-center">
            <b-col v-if="fixture.local_team">
              <div>
                <b-img
                  center
                  height="auto"
                  width="100"
                  :src="fixture.local_team.logo_path"
                />
              </div>
              <div class="text-center mt-1">
                <span class="h4">{{ fixture.local_team.name['fr'] }}</span>
                <template v-if="fixture.winner_team">
                  <feather-icon
                    v-show="fixture.winner_team.team_id === fixture.local_team.team_id"
                    size="14"
                    class="text-success ml-1"
                    icon="CheckCircleIcon"
                  />
                </template>
              </div>
            </b-col>
            <b-col v-if="fixture.scores">
              <div class="text-center">
                <h1>{{ fixture.scores.ft_score }}</h1>
                <h5>{{ fixture.scores.ps_score }}</h5>
              </div>
            </b-col>
            <b-col v-if="fixture.visitor_team">
              <div>
                <b-img
                  center
                  height="auto"
                  width="100"
                  :src="fixture.visitor_team.logo_path"
                />
              </div>
              <div class="text-center mt-1">
                <span class="h4">{{ fixture.visitor_team.name['fr'] }}</span>
                <template v-if="fixture.winner_team">
                  <feather-icon
                    v-show="fixture.winner_team.team_id === fixture.visitor_team.team_id"
                    size="14"
                    class="text-success ml-1"
                    icon="CheckCircleIcon"
                  />
                </template>
              </div>
            </b-col>
          </b-row>
          <h3 class="text-center">
            Évènements :
          </h3>
          <b-list-group flush>
            <b-list-group-item
              v-for="(event,index) in fixture.events"
              :key="index"
              class="text-center"
            >
              <span class="mr-1">{{ event.type }}</span>
              <b-img
                class="mr-1"
                height="auto"
                width="20"
                :src="event.fixture_team.logo_path"
              />
              <span class="mr-1">{{ event.player_name }}</span>
              <span>{{ event.minute }}</span>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
    <h3 class="text-center mb-3 mt-1">
      Pronostics
    </h3>
    <CompetitionsViewForecastsList
      :forecast-type="'fixture'"
      :fixture="fixture"
      :api-url="api_url"
    />
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BImg,
  BRow,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BForm,
  BFormInput,
  BTable,
  BPagination, BLink,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import CompetitionsViewForecastsList from '@/views/competitions/competitions-view/forecasts-list/CompetitionsViewForecastsList'
import axiosIns from '@/libs/axios'
import vSelect from 'vue-select'

export default {
  name: 'CompetitionsViewFixturesView',
  components: {
    BBadge,
    BRow,
    BCol,
    BCard,
    BButton,
    BImg,
    BSpinner,
    BListGroup,
    BListGroupItem,
    vSelect,
    BForm,
    BFormInput,
    BTable,
    BPagination,
    BLink,
    CompetitionsViewForecastsList,
  },
  setup() {
    const resolveStatusVariant = status => {
      if (status === 'PENDING') return 'light-info'
      if (status === 'RUNNING') return 'light-success'
      if (status === 'FINISHED') return 'light-warning'
      return 'light-success'
    }
    const resolveStatusGoodForecast = bool => (bool ? 'light-success' : 'light-danger')
    const pointsToWin = fixture => {
      const { stage } = fixture
      if (stage === '8th Finals' || stage === 'Quarter-finals' || stage === 'Semi-finals' || stage === '3rd Place Final' || stage === 'Final') {
        return '6'
      }
      return '3'
    }
    return {
      avatarText,
      resolveStatusVariant,
      pointsToWin,
      resolveStatusGoodForecast,
    }
  },
  data() {
    return {
      loading: true,
      loadingForecasts: true,
      perPageOptions: [5, 25, 50, 100],
      forecasts: [],
      perPage: 25,
      searchQuery: '',
      fixture: {},
      sortBy: 'name',
      isSortDirDesc: false,
      api_url: `/fixtures/${this.$route.params.id}/forecasts`,
      tableColumns: [
        {
          key: 'user',
          label: 'Utilisateur',
          sortable: false,
        },
        {
          key: 'pts_won',
          label: 'points gagnés',
          sortable: false,
        },
        {
          key: 'forecast',
          label: 'Pronostic',
          sortable: false,
        },
      ],
      currentPage: 1,
      totalForecasts: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
        total: 0,
      },
    }
  },
  mounted() {
    this.fetchFixture()
    this.fetchForecasts()
  },
  beforeDestroy() {
    this.$store.commit('app/UPDATE_PAGE_META', { pageTitle: '', breadcrumb: [] })
  },
  methods: {
    async fetchFixture() {
      try {
        this.loading = true
        const { data } = await axiosIns(`fixtures/${this.$route.params.id}`)
        this.fixture = data.data
        this.$store.commit('app/UPDATE_PAGE_META', {
          pageTitle: 'match',
          breadcrumb: [
            {
              text: 'Competitions',
              active: false,
              to: {
                name: 'competitions',
              },
            },
            {
              text: this.$route.params.idCompetition,
              active: false,
              to: {
                name: 'competitions-view',
                params: { id: this.$route.params.idCompetition },
              },
            },
            {
              text: 'Matchs',
              active: true,
            },
            {
              text: 'match',
              active: true,
            },
          ],
        })
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async fetchForecasts() {
      try {
        this.loadingForecasts = true
        const { data } = await axiosIns(`fixtures/${this.$route.params.id}/forecasts`, {
          params: {
            perPage: this.perPage,
            page: this.currentPage,
          },
        })
        this.forecasts = data.data
        this.loadingForecasts = false
        this.dataMeta.from = data.meta.form
        this.dataMeta.to = data.meta.to
        this.dataMeta.total = data.meta.total
        this.totalForecasts = data.meta.total
      } catch (err) {
        this.loadingForecasts = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    changePage(e) {
      this.currentPage = e
      this.fetchForecasts()
    },
  },
}
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
